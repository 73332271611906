import Head from "next/head";
import Image from "next/image";

const Link = ({ text, url, color }) => (
  <a
    href={url}
    target="_blank"
    className="hover:underline"
    rel="noopener"
    style={color ? { color } : { color: "#0070F3" }}
  >
    {text}
  </a>
);

export default function Home() {
  return (
    <div className="text-neutral-900 w-full h-full min-h-screen">
      <Head>
        <title>Jerry Wang</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="description"
          content="My name is Jerry. I'm currently at Triangle Labs."
        />
      </Head>

      <main
        className="container mx-auto p-8 py-12 max-w-3xl md:h-full md:min-h-screen md:flex md:flex-col md:justify-center md:align-items"
        style={{ fontFamily: "BerkeleyMono" }}
      >
        <h1 className="text-3xl mb-4 mt-4 text-black">Jerry Wang</h1>
        <p className="mb-12 text-sm" style={{ color: "#666" }}>
          Updated June 1st, 2023
        </p>
        <div style={{ color: "#444" }}>
          <p className="mb-8">
            I'm currently at{" "}
            <Link url="https://trianglelabs.com/" text="Triangle Labs" />.
          </p>

          <p className="mb-8">
            I used to lead engineering at{" "}
            <Link
              url="https://www.forbes.com/sites/frederickdaso/2021/07/27/scout-fresh-off-of-raising-12-million-has-a-growing-waitlist-of-over-150000-people-hyped-about-the-metaverse/?sh=42c53dcf2cb1"
              text="Scout"
            />
            , creating{" "}
            <Link
              url="https://fromscout.com/"
              text="platforms"
            /> for content creators. Prior to that,
            I was developing the cloud at{" "}
            <Link url="https://aws.amazon.com/ebs/" text="Amazon" /> and making
            baby food at <Link url="https://helloyumi.com/" text="Yumi" />. I
            studied CS &amp; Business at USC.
          </p>
          <p className="mb-8">
            I spend most of my time building software, but I also roast and brew coffee, mix drinks, explore food and its
            narratives, work on a supper club, maintain a hydroponic garden, and
            hike. I really like critical scholarship, affect theory, medical
            anthropology, philosophy, psychology, neuroscience, functional
            programming, and e-sports.
          </p>
        </div>
      </main>
    </div>
  );
}
